<template>
  <div class="tw-block">
    <label class="custom-label tw-flex tw-relative">
      <div
        class="checkbox-parent tw-bg-white tw-flex tw-justify-center tw-items-center tw-mr-2 tw-rounded-sm tw-cursor-pointer"
        style="padding:4px"
        :style="{
          backgroundColor: isChecked ? ' #1A64E2' : 'transparent',
          boxShadow: isChecked ? '0 0 0 2px #1A64E2' : '0 0 0 2px #AEAEAE',
        }"
      >
        <input type="checkbox" class="tw-hidden" :checked="isChecked" :value="id" @change="onChange" />
        <svg class="tw-hidden tw-w-5 tw-h-4  tw-pointer-events-none" viewBox="0 0 172 172">
          <g fill="none">
            <path d="M0 172V0h172v172z" />
            <path d="M 142 34 L 63 110 L 35 85 l -15 17 L 65 141 l 91.067 -91.067 z" fill="#ffffff" stroke-width="1" />
          </g>
        </svg>
      </div>
      <span class="tw-select-none tw-ml-8 tw-w-max tw-text-left" style="line-height:21px; color: #2E2E2E">{{ label }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Boolean],
      default: () => [],
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  computed: {
    isChecked() {
      if (this.value instanceof Array) {
        return this.value && this.value.indexOf(this.id) !== -1;
      }
      return this.value;
    },
  },
  methods: {
    onChange(event) {
      let isChecked = event.target.checked;
      let newValue = null;
      //this.$emit('change', val.target.value)
      if (this.value instanceof Array) {
        newValue = [...this.value];

        if (isChecked) {
          newValue.push(this.id);
        } else {
          newValue.splice(newValue.indexOf(this.id), 1);
        }
      } else {
        newValue = isChecked;
      }
      this.$emit('change', newValue);
    },
  },
};
</script>

<style>
.checkbox-parent {
  position: absolute;
  transition: all 0.1s;
  width: 20px;
  height: 20px;
}
.custom-label input:checked + svg {
  display: block !important;
  background: #1a64e2;
}
</style>
