<template>
  <div class="custom-radio tw-flex tw-items-center" :class="{ inverted: inverted }">
    <input
      :id="id"
      type="radio"
      :name="name"
      :class="className"
      :checked="checked"
      :value="value"
      :required="required"
      @change="updateInput"
    />
    <label class="tw-flex tw-items-center tw-justify-center tw-text-base" :for="id"
      >{{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
      default: ''
    },
    className: {
      type: String,
      required: false,
      default: ''
    },
    id: {
      type: [String, Number],
      required: false,
      default: ''
    },
    value: {
      type: [String, Number],
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('input', event.target.value);
    }
  }
};
</script>

<style>
.custom-radio {
  padding-right: 10px;
  margin-right: 5px;
}
.custom-radio input[type='radio'],
.custom-radio label {
  user-select: none;
  display: inline-block;
  vertical-align: middle;
}
.custom-radio input[type='radio'] {
  display: none;
}
.custom-radio input[type='checkbox'],
.custom-radio input[type='radio'] {
  -webkit-appearance: none;
  transition: all 0.2s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-radio input[type='checkbox']:focus,
.custom-radio input[type='radio']:focus {
  outline: 0;
}
.custom-radio input[type='checkbox']:active,
.custom-radio input[type='radio']:active {
  outline: none;
}
.custom-radio input[type='checkbox']:hover,
.custom-radio input[type='radio']:hover {
  outline: none;
}
.custom-radio input[type='radio'] + label {
  cursor: pointer;
  padding-left: 28px;
  position: relative;
}

.custom-radio input[type='radio'] + label:before {
  content: '';
  top: 0px;
  left: 0;
  width: 18px;
  height: 18px;
  position: absolute;
  background: #1a64e2;
  border-radius: 100%;
  transition: all 0.3s ease;
  border: 1px solid #949ba1;
  box-shadow: inset 0 0 0 18px #ffffff;
}
.custom-radio input[type='radio']:checked + label:before {
  border: 1px solid #1a64e2;
  box-shadow: inset 0 0 0 2px #fff;
}
.custom-radio.inverted input[type='checkbox'],
.custom-radio.inverted input[type='radio'] {
  border: 1px solid black;
}
.custom-radio.inverted input[type='checkbox']:focus,
.custom-radio.inverted input[type='radio']:focus {
  border: 1px solid black;
}
.custom-radio.inverted input[type='checkbox']:checked,
.custom-radio.inverted input[type='radio']:checked {
  background: black;
}
</style>
