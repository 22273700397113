<template>
  <div class="tw-block">
    <div class="tw-mb-12 tw-text-left">
      <span class="tw-font-bold tw-text-2xl tw-block tw-mb-2">Manage Preferences</span>
      <span style="color:#2e2e2e">Uncheck the category of emails you would not like to receive in future. </span>
    </div>
    <div class="tw-pb-8 custom-scroll" style="max-height: calc(100vh - 340px);">
      <checkbox-group v-for="cate in data" :key="cate.label" :label="cate.label" :data="cate.value" :needParentCheckbox="cate.value.length > 1 && false"
      @group-changed="onGroupChange"
      class="tw-mb-12" />
    </div>
    <hr class="tw-mb-10 tw-mt-6" style="border-color:#2E3E4E"/>
    <checkbox :label="'Unsubscribe from all'"  v-model="isSelectedAll" @change="onSelectAll" />
    
  </div>
</template>

<script>
import Checkbox from './ui/Checkbox.vue';
import CheckboxGroup from './ui/CheckboxGroup.vue';
export default {
  components: { CheckboxGroup, Checkbox },
  props:{
    data:{
      type: Array,
      required: true
    }
  },
  data(){
    return {
      isSelectedAll: false
    }
  },
  methods:{
    onGroupChange(data){
      this.$emit('on-group-change', data);
      this.isSelectedAll = false;
      this.$emit('unsubscribe-from-all', false)
    },
    onSelectAll(isSelected){
      this.$emit('unsubscribe-from-all', isSelected)
      this.data.forEach(item=>{
        item.value.forEach(k=> k.value = !isSelected);
      });
      this.$emit('on-group-change', this.data);
      
    }
  }
};
</script>
