<template>
  <div class="tw-text-left">
    <div class="tw-mb-4" style="color: #2e2e2e">
      To help us improve our service, can you please tell us why you
      unsubscribed?
    </div>
    <div>
      <radio-input
        :id="QuestionSet.EmailNotRelevant"
        class="radio-item tw-py-2 tw-text-sm tw-text-black-72"
        :name="'cancel-feedback'"
        :value="QuestionSet.EmailNotRelevant"
        label="The emails are not relevant to me"
        :checked="QuestionSet.EmailNotRelevant === selectedQuestion"
        @input="updateReason"
      />
      <radio-input
        :id="QuestionSet.TooManyEmails"
        class="radio-item tw-py-2 tw-text-sm tw-text-black-72"
        :name="'cancel-feedback'"
        :value="QuestionSet.TooManyEmails"
        label="I receive too many emails from RAx"
        :checked="QuestionSet.TooManyEmails === selectedQuestion"
        @input="updateReason"
      />
      <radio-input
        :id="QuestionSet.DontRememberSignUp"
        class="radio-item tw-py-2 tw-text-sm tw-text-black-72"
        :name="'cancel-feedback'"
        :value="QuestionSet.DontRememberSignUp"
        label="I don't remember signing up for this"
        :checked="QuestionSet.DontRememberSignUp === selectedQuestion"
        @input="updateReason"
      />
      <radio-input
        :id="QuestionSet.NoLongerWantToReceive"
        class="radio-item tw-py-2 tw-text-sm tw-text-black-72"
        :name="'cancel-feedback'"
        :value="QuestionSet.NoLongerWantToReceive"
        label="I no longer want to receive these emails"
        :checked="QuestionSet.NoLongerWantToReceive === selectedQuestion"
        @input="updateReason"
      />
      <radio-input
        :id="QuestionSet.SpamAndReported"
        class="radio-item tw-py-2 tw-text-sm tw-text-black-72"
        :name="'cancel-feedback'"
        :value="QuestionSet.SpamAndReported"
        label="The emails are spam and should be reported"
        :checked="QuestionSet.SpamAndReported === selectedQuestion"
        @input="updateReason"
      />
      <div>
        <radio-input
          :id="QuestionSet.Others"
          class="radio-item tw-py-2 tw-text-sm tw-text-black-72"
          :name="'cancel-feedback'"
          :value="QuestionSet.Others"
          label="Others"
          :checked="QuestionSet.Others === selectedQuestion"
          @input="updateReason"
        />
        <textarea
          v-if="QuestionSet.Others == selectedQuestion"
          v-model="otherMsg"
          style="min-height: 95px"
          class="custom-input tw-text-left tw-block tw-ml-4 tw-mt-2 tw-h-8"
          placeholder="Tell us more about your issue..."
          rows="2"
          cols="50"
        />
      </div>
    </div>

    <button
      id="feedback"
      class="btn tw-mt-8 tw-text-left"
      :class="{
        'tw-cursor-not-allowed tw-opacity-75':
          selectedQuestion === null ||
          (selectedQuestion == QuestionSet.Others && !otherMsg),
      }"
      @click="submitFeedback"
    >
      Submit
    </button>
  </div>
</template>

<script>
import RadioInput from "./ui/RadioInput.vue";

const QuestionSet = {
  EmailNotRelevant: 0,
  TooManyEmails: 1,
  DontRememberSignUp: 2,
  NoLongerWantToReceive: 3,
  SpamAndReported: 4,
  Others: 5,
};
export default {
  components: { RadioInput },
  data() {
    return {
      QuestionSet,
      selectedQuestion: null,
      otherMsg: "",
    };
  },
  methods: {
    updateReason(e) {
      this.selectedQuestion = e;
    },
    async submitFeedback() {
      if (
        this.selectedQuestion === null ||
        (this.selectedQuestion == QuestionSet.Others && !this.otherMsg)
      ) {
        return;
      }
      const data = {
        feedbackOptionId: this.selectedQuestion,
        feedback:
          QuestionSet.Others == this.selectedQuestion
            ? this.otherMsg
            : undefined,
      };
      this.$emit("on-feedback", data);
    },
  },
};
</script>

<style></style>
