<template>
  <div id="app">
    <Subscription />
  </div>
</template>

<script>
import Subscription from './components/Subscription.vue'

export default {
  name: 'App',
  components: {
    Subscription
  }
}
</script>

<style>
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-align: center;
}
</style>
