<template>
  <div class="tw-mt-9 tw-mx-auto sm:tw-w-7/12 tw-w-full">
    <div v-if="currentState === StateForPage.Unsubscribed " class="tw-flex tw-flex-col tw-items-center">
      <div class="tw-flex tw-flex-col tw-p-6 tw-bg-white tw-rounded-lg">
        <h2 class="title tw-text-2xl tw-mb-2 tw-text-left tw-font-bold">Unsubscribe Successful</h2>
        <p class="subtitle tw-text-left" style="color:#2E2E2E">You will no longer receive emails from <span class="tw-font-bold">{{ unsubscribedCategory? unsubscribedCategory.label: 'this' }}</span> list. 
        <span @click="currentState = StateForPage.Manage" class="tw-underline tw-cursor-pointer" style="color:#1A64E2;
">
          Manage email preferences
        </span>
        </p>
        <unsubscribe-feedback v-if="!hasFeedbackProvided" class="tw-mt-8" @on-feedback="feedbackProvided" />
      </div>
    </div>
    <div v-if="currentState === StateForPage.Updated " class="tw-flex tw-flex-col tw-items-center">
      <div class="tw-flex tw-flex-col tw-p-6 tw-bg-white tw-rounded-lg">
        <h2 class="title tw-text-2xl tw-mb-4 tw-text-left">Updated successfully</h2>
        <div class="tw-flex tw-w-full tw-flex-row-reverse tw-mt-4">
          <a @click="currentState = StateForPage.Manage" class="tw-cursor-pointer tw-text-blue-700 hover:tw-underline">Manage your preferences</a>
        </div>
      </div>
    </div>
    <div v-if="currentState === StateForPage.Manage" class="tw-mx-12">
      <manage-preference :data="manageUsageData" @on-group-change="onGroupChange" @unsubscribe-from-all="unsubscribeFromAll = $event" />
      <div class="tw-mt-9 tw-flex">
        <button id="unsubscribe" class="btn" :class="{'tw-cursor-not-allowed tw-opacity-75' : !hasChangedPref}" @click="updatePreferenceOnChange">Update Preferences</button>
      </div>
    </div>
    <div v-if="currentState === StateForPage.Loading" class="tw-m-auto tw-bg-white tw-p-6 tw-rounded-lg tw-text-2xl">
      {{loadingMsg}}
    </div>
    <div v-if="currentState === StateForPage.Error" class="tw-m-auto tw-bg-white tw-p-6 tw-rounded-lg tw-text-2xl">
      Something went wrong.
    </div>
  </div>
</template>

<script>
const StateForPage = {
  Unsubscribed : 'unsubscribed',
  Error: 'error',
  Manage: 'manage',
  Loading: 'loading',
  Updated: 'updated'
}
import ManagePreference from './ManagePreference.vue';
import UnsubscribeFeedback from './UnsubscribeFeedback';
export default {
  components: { ManagePreference, UnsubscribeFeedback },
  name: 'subscription',
  props: {
    msg: String,
  },
  data() {
    return {
      StateForPage,
      currentState: StateForPage.Loading,
      loadingMsg: 'Fetching your preferences...',
      showManagePreferences: false,
      hasChangedPref: false,
      unsubscribedCategory: null,
      manageUsageData: [],
      subcategoryId: null,
      reqToken: null,
      unsubscribeFromAll:false,
      hasFeedbackProvided: false,
    };
  },
  methods: {
    async feedbackProvided(data){
      try {
        await this.$axios.post(`user/email-unsubscribe/feedback`,{
        token: this.reqToken,
        subcategoryId: this.subcategoryId,
        ...data
      });
       this.hasFeedbackProvided = true;
      } catch (error) {
        this.hasFeedbackProvided = true;
        console.log(error);
      }
    },
    async updatePreferenceOnChange(){

      await this.updatePreference(this.manageUsageData);
      this.currentState = StateForPage.Updated;
    },
    async updatePreference(data) {
      this.hasChangedPref = false;
      try {
        await this.$axios.post(`user/unsubscribe-preferences/update?token=${this.reqToken}`,{
        updatedPreferences: data,
        unsubscribeFromAll: this.unsubscribeFromAll
      });
      this.showManagePreferences = false;
      } catch (error) {
        this.currentState = StateForPage.Error;
        console.log(error);
      }
     
    },
    async fetchPreferences() {
      try {
        let { data } = await this.$axios.get(`user/unsubscribe-preferences?token=${this.reqToken}`);
        this.manageUsageData = data;
      } catch (error) {
        this.currentState = StateForPage.Error;
      }
    },
    onGroupChange() {
      this.hasChangedPref = true;
    },
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.reqToken = urlParams.get('token');
    this.subcategoryId = urlParams.get('subcategoryId');
    if (!this.reqToken || !this.subcategoryId) {
      this.currentState = StateForPage.Error;
      return;
    }
    
    this.currentState = StateForPage.Loading
    await this.updatePreference([{
      label:'',
      value: [
        {
          id: this.subcategoryId,
          value: false
        }
      ]
    }])
    await this.fetchPreferences();
    for (let index = 0; index < this.manageUsageData.length; index++) {
      const element = this.manageUsageData[index];
       const item = element.value.find(j=> j.id == this.subcategoryId);
       if (item) {
         this.unsubscribedCategory = item;
         break;
       }
    }
    this.currentState = StateForPage.Unsubscribed;
  },
};
</script>
