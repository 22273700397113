<template>
  <div class="tw-block tw-ml-0.5">
      <checkbox v-if="needParentCheckbox" :label="label"  v-model="isSelectedAll" @change="onParentChange"  />
      <div v-else class="tw-select-none tw-text-left tw-text-xl tw-font-medium">{{ label }} </div>
      <div>
           <checkbox v-for="(item) of data" :key="item.id" :id="item.id" :label="item.label" v-model="item.value" @change="onChange" class="tw-mt-4" />
      </div>

  </div>
</template>

<script>
import Checkbox from './Checkbox.vue';
export default {
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    data:{
        type: Array,
        default: ()=> [ ]
    },
    needParentCheckbox:{
        type: Boolean,
        default: true
    }
  },
  components:{
      Checkbox
  },
  data(){
      return {
          selectedItems: this.data.filter(p=> p.value).map(j=> j.id),
          isSelectedAll: false
      }
  },
  watch:{
      selectedItems : {
          handler: 'onSelectionChange',
          deep: true
      },
  },
  methods:{
      onChange(){
          this.$emit('group-changed', this.data);
      },
      onSelectionChange(){
          this.isSelectedAll = this.selectedItems.length === this.data.length;
      },
      onParentChange(val){
          if (val) {
              this.selectedItems = this.data.map(j=> j.id)
          } else {
              this.selectedItems = [];
          }
      }
  },
  mounted(){
      this.isSelectedAll = this.selectedItems.length === this.data.length;
  }
};
</script>

<style>
.custom-label input:checked + svg {
  display: block !important;
}
</style>
